//
// Main
//
// Import this file using the following HTML or equivalent:
// <script src="dist/js/main.min.js" type="text/javascript"></script>
//

jQuery( document ).ready(
	function( $ ) {
		if ( typeof $.magnificPopup === 'function' ) {

			$( '.magnific-popup' ).magnificPopup(
				{

					type: 'image'

				}
			);

		}

		if ( typeof $.mmenu === 'function' ) {

			$( '#nav' ).mmenu(
				{}, {
					clone: true
				}
			);

		}

		$( '.popup-modal' ).magnificPopup( {
			type: 'inline',
			preloader: false,
			focus: '#username',
			modal: true,
			callbacks: {
				open: function() {
					setTimeout( function() {
						$.magnificPopup.close();
					}, 15000 );
				}
			}
		} );

		$( document ).on( 'click', '.popup-modal-dismiss', function( e ) {
			e.preventDefault();
			$.magnificPopup.close();
		} );
		$( '#search-btn' ).click(
			function() {

				$( '.header__search' ).show();

				$( '.header__search .field' ).focus();

				$( '.wrapper__overlay' ).show();

				return false;
			}
		);

		$( '.wrapper__overlay' ).click(
			function() {

				$( this ).fadeOut();

				$( '.header__search' ).fadeOut();

				return false;
			}
		);

		$( '.article .article__team ul li:first-child a:first-child' ).click(
			function() {

				$( this ).hide();

				$( this ).parent().parent().parent().find( 'ol li' ).addClass( 'full' );
				$( '.article .article__team ol li.full .full-content' ).slideDown( 'fast' );
				$( '.article .article__team ol li.full .short-content' ).hide();
				$( this ).parent().parent().parent().find( 'ul li:first-child a:last-child' ).show();

				return false;
			}
		);

		$( '.article .article__team ul li:first-child a:last-child' ).click(
			function() {

				$( this ).hide();

				$( this ).parent().parent().parent().find( 'ol li' ).removeClass( 'full' );
				$( '.article .article__team ol li .full-content' ).slideUp( 'fast' );
				$( '.article .article__team ol li .short-content' ).fadeIn();
				$( this ).parent().parent().parent().find( 'ul li:first-child a:first-child' ).show();

				return false;
			}
		);

		$( '.sidebar .sidebar__box .menu > li.menu-item-has-children > a, .sidebar .sidebar__box .menu > li > ul > li.menu-item-has-children > a' ).prepend( '<i class="fa fa-caret-down"></i>' );

		$( 'a[href=\'#top\']' ).on(
			'click', function( event ) {

				event.preventDefault();

				$( 'html, body' ).animate( { scrollTop: '0px' } );

			}
		);

		$( '.intro__more a' ).click( function() {
			if ( location.pathname.replace( /^\//, '' ) == this.pathname.replace( /^\//, '' ) && location.hostname == this.hostname ) {
				var target = $( this.hash );
				target = target.length ? target : $( '[name=' + this.hash.slice( 1 ) + ']' );
				if ( target.length ) {
					$( 'html, body' ).animate( {
						scrollTop: target.offset().top
					}, 1000 );
					return false;
				}
			}
		} );

		$( '.fancybox-button' ).fancybox(
			{
				openEffect: 'none',
				closeEffect: 'none',
				prevEffect: 'none',
				nextEffect: 'none',
				closeBtn: false,
				helpers: {
					title: {
						type: 'inside'
					},
					buttons: {}
				}
			}
		);

		var link = $( ".article .article__eventsingle #contact" ).val();

		$( ".article .article__eventsingle .ssba-wrap a.ssba_email_share" ).attr( 'href', link );

		if ( $( "body" ).hasClass( "single-agenda" ) ) {
			$( ".sidebar .sidebar__box .menu li#menu-item-428" ).addClass( "current-menu-item" );
		}
		if ( $( "body" ).hasClass( "tax-agenda_cat" ) ) {
			$( ".sidebar .sidebar__box .menu li#menu-item-428" ).addClass( "current-menu-item" );
		}

		$( '#pagger' ).jPages(
			{
				delay: 50,
				perPage: 9,
				minHeight: false,
				next: 'Volgende',
				direction: 'auto',
				previous: 'Vorige',
				containerID: 'gallery',
				animation: 'fadeInDown',
			}
		);

		$( '.article__gallery select' ).change(
			function() {

				$( "#filter" ).submit();

			}
		);

		$( '.toolwrap' ).mouseenter(
			function() {
				$( this ).children( '.tooltip' ).fadeIn();
			}
		);

		$( '.toolwrap' ).mouseleave(
			function() {
				$( this ).children( '.tooltip' ).hide();
			}
		);

		if ( $( window ).width() < 767 ) {
			$( "ul.mm-listview li a.mm-next" ).click(
				function() {

					$( this ).next().children( 'cite' ).text( '' );
					var text = $( this ).next().children( 'span' ).text();
					$( '.mm-navbar .mm-title' ).text( text );

				}
			);

			$( ".mm-navbar a.mm-prev,.mm-navbar .mm-title" ).click(
				function() {

					$( '.mm-navbar .mm-title' ).text( 'Menu' );

				}
			);
		}

		$( ".font-button" ).bind(
			"click", function() {
				var max = 22;
				var size = parseInt( $( 'body, html' ).css( "font-size" ) );

				if ( $( this ).hasClass( "plus" ) ) {

					if ( size < max ) {
						size = size + 2;
					}

				} else {
					size = size - 2;
					if ( size <= 16 ) {
						size = 16;
					}
				}

				var per = parseInt( size * 100 / 16 );
				eraseCookie( 'font_resize' );
				createCookie( 'font_resize', per, 365 );
				$( '.header__size cite ' ).text( readCookie( 'font_resize' ) + '%' );
				$( 'body, html' ).css( "font-size", size );
			}
		);

		if ( readCookie( 'font_resize' ) ) {
			var size = 16;
			$( 'body, html' ).css( "font-size", Math.round( size * readCookie( 'font_resize' ) / 100 ) );
		}

		if ( readCookie( 'font_resize' ) === null ) {

			$( '.header__size cite ' ).text( '100' + '%' );

		} else {

			$( '.header__size cite ' ).text( readCookie( 'font_resize' ) + '%' );
		}

		function createCookie( name, value, days ) {
			if ( days ) {
				var date = new Date();
				date.setTime( date.getTime() + (days * 24 * 60 * 60 * 1000) );
				var expires = "; expires=" + date.toGMTString();
			}
			else {
				var expires = "";
			}
			document.cookie = name + "=" + value + expires + "; path=/";
		}

		function readCookie( name ) {
			var nameEQ = name + "=";
			var ca = document.cookie.split( ';' );
			for ( var i = 0; i < ca.length; i ++ ) {
				var c = ca[ i ];
				while ( c.charAt( 0 ) == ' ' ) {
					c = c.substring( 1, c.length );
				}
				if ( c.indexOf( nameEQ ) == 0 ) {
					return c.substring( nameEQ.length, c.length );
				}
			}
			return null;
		}

		function eraseCookie( name ) {
			createCookie( name, "", - 1 );
		}

		$( '.week-slider' ).slick( {
			vertical: true,
			slidesToShow: 3,
			slidesToScroll: 1,
			arrows: false,
			verticalSwiping: true,
			autoplay: true,
			adaptiveHeight: false,
			autoplaySpeed: 10000,
			infinite: true,
			responsive: [
				{
					breakpoint: 768,
					settings: {
						slidesToShow: 3,
						verticalSwiping: true
					}
				}
			]
		} );
		$( '.coming-slider' ).slick( {
			vertical: true,
			slidesToShow: 2,
			slidesToScroll: 1,
			arrows: false,
			autoplay: true,
			autoplaySpeed: 5000,
			infinite: true,
			responsive: [
				{
					breakpoint: 768,
					settings: {
						verticalSwiping: true
					}
				}
			]

		} );

		$( '.news-slider' ).slick( {
			vertical: true,
			slidesToShow: 2,
			slidesToScroll: 1,
			arrows: false,
			// autoplay: true,
			adaptiveHeight: true,
			// autoplaySpeed: 5000,
            verticalSwiping: true,
            verticalScrolling: true,
			infinite: false,
			responsive: [
				{
					breakpoint: 768,
					// settings: {
					// 	verticalSwiping: true
					// }
				}
			]

		} );
		$( document ).on( 'mousewheel', '.coming-slider,.news-slider', function( event ) {
			event.preventDefault();
			if ( event.deltaX > 0 || event.deltaY < 0 ) {
				$( this ).slick( 'slickNext' );
			} else if ( event.deltaX < 0 || event.deltaY > 0 ) {
				$( this ).slick( 'slickPrev' );
			}
		} );
		$( '.hr-slider' ).slick( {
			slidesToShow: 1,
			slidesToScroll: 1,
			arrows: false,
			dots: true,
			autoplay: true,
			autoplaySpeed: 5000
		} )

	}
);
